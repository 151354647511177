// export const API_BASE = "https://ddsplay-devapi.devtechnosys.tech/api/";
// export const API_BASE = "https://ddsplay-prodapi.devtechnosys.tech/api/";

// const API = "http://172.16.11.30:17173"; //mine
// const API = "http://172.16.11.239:17173"; //sp
// const API = "http://172.16.3.190:17173"; //sahil
// const API = "http://172.16.1.149:3030"; //sunil
const API = "https://ddsplay-prodapi.devtechnosys.tech";
// const API = "https://ddsplay-devapi.devtechnosys.tech";

export const API_BASE = API + "/api/";
export const IMAGE_BASE = API + "/";
// export const IMAGE_BASE = "https://ddsplay-prodapi.devtechnosys.tech/";

// export const IMAGE_BASE = "http://172.16.2.190:3030/";

export const BASE_API = API_BASE + "/api";
export const MAIN_URL = BASE_API + "/api/admin";

export const MOBILE_REGEX = /^\+?\d{1,4}?\d{6,15}$/g;

export const PASSWORDS_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%^&*?])[A-Za-z\d#$@!%^&*?]{8,}$/g;

export const USERNAME_REGEX =
  /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+={}\[\]:;"'<>,.?/~`-]{3,64}$/;
export const EMAIL_REGEX =
  /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/;

export const NOSPACE_REGEX = /^(?!\s+$).*/;

export const NOSPACE = "Spaces are not allowed";

export const IMAGE_FORMATS = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/tiff",
  "image/svg+xml",
  "image/vnd.adobe.photoshop",
  "image/vnd.dwg",
  "image/x-canon-cr2",
];
export const VIDEO_FORMATS = [
  "video/mp4",
  "video/webm",
  "video/ogg",
  "video/mov",
];

const audioFormats = [
  "mp3",
  "wav",
  "ogg",
  "aac",
  "flac",
  "wma",
  "m4a",
  "aiff",
  "opus",
  // Add more formats as needed
];

export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "video/mp4",
  "video/x-ms-wmv",
  "video/quicktime",
  "video/webm",
  "application/pdf",
  "text/plain",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

export const MULTIPLESPACE = /  +/g;

export const CARD_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/g;

export const NODIGITREGEX = /^([^0-9]*)$/;

export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

export const REMOVEHTML = /(<([^>]+)>)/gi;
